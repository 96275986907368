<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-card :elevation="1">
                    <v-card-text class="py-1">
                        <v-row dense>
                            <v-col cols="12" md="2">
                                <v-combobox
                                    label="Department"
                                    dense
                                    outlined
                                    hide-details
                                    :items="$store.getters['department/departments']"
                                    v-model="department"
                                    item-text="name"
                                    item-value="id"
                                    :loading="$store.getters['department/loadingDepartments']"
                                    @focus="getDepartments"
                                    class="custom-form"
                                ></v-combobox>
                            </v-col>
                            
                            <v-col cols="12" md="2">
                                <v-combobox
                                    label="Designation"
                                    dense
                                    outlined
                                    hide-details
                                    :items="$store.getters['designation/designations']"
                                    v-model="designation"
                                    item-text="name"
                                    item-value="id"
                                    :loading="$store.getters['designation/loadingDesignations']"
                                    @focus="getDesignations"
                                    class="custom-form"
                                ></v-combobox>
                            </v-col>
                            
                            <v-col cols="12" md="2">
                                <v-combobox
                                    label="Shift"
                                    dense
                                    outlined
                                    hide-details
                                    :items="$store.getters['shift/shifts']"
                                    v-model="shift"
                                    item-text="name"
                                    item-value="id"
                                    :loading="$store.getters['shift/loadingShifts']"
                                    @focus="getShifts"
                                    class="custom-form"
                                ></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-combobox
                                    label="Employee"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="employee"
                                    :items="$store.getters['employee/employees']"
                                    :loading="$store.getters['employee/loadingEmployees']"
                                    item-text="display_name"
                                    item-value="id"
                                    class="mb-1 custom-form"
                                    @focus="getEmployees"
                                ></v-combobox>
                            </v-col>

                            <v-col cols="6" md="1">
                                <v-btn
                                    elevation="1"
                                    small
                                    color="primary"
                                    dark
                                    style="margin-top:-2px"
                                    :loading="loading"
                                    @click="getResults"
                                > Search </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="results.length > 0">
            <v-col cols="12" md="12" class="custom-form py-1">
                <v-card :elevation="1">
                    <v-toolbar dense color="white" :elevation="1" height="40px">
                        <v-spacer></v-spacer>

                        <v-menu
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    dense
                                    outlined
                                    hide-details
                                    v-model="date"
                                    label="Date"
                                    v-on="on"
                                    class="custom-form"
                                    style="max-width: 100px;"
                                    @input="setDate"
                                ></v-text-field>
                            </template>
                            <v-date-picker @change="setDate" v-model="date"></v-date-picker>
                        </v-menu>

                        <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model="remark"
                            label="Remark"
                            type="text"
                            class="mx-2"
                            style="max-width: 200px;"
                            @input="setRemark"
                        ></v-text-field>

                    </v-toolbar>

                    <v-card-text class="pa-0">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>E.Id</th>
                                        <th>Name</th>
                                        <th>Phone</th>
                                        <th>Designation</th>
                                        <th>Department</th>
                                        <th>Shift</th>
                                        <th>Date</th>
                                        <th>OT Hours</th>
                                        <th>Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(employee, sl) in results" :key="sl">
                                        <td>{{ employee.emp_id }}</td>
                                        <td>{{ employee.name }}</td>
                                        <td>{{ employee.phone }}</td>
                                        <td>{{ employee.designation_name }}</td>
                                        <td>{{ employee.department_name }}</td>
                                        <td>{{ employee.shift_name }}</td>
                                        <td>
                                            <v-menu
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="employee.ot_date"
                                                        v-on="on"
                                                        class="custom-form"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="employee.ot_date"></v-date-picker>
                                            </v-menu>
                                        </td>
                                        <td>
                                            <v-text-field
                                                type="text"
                                                dense
                                                outlined
                                                hide-details
                                                v-model="employee.ot_hours"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                type="text"
                                                dense
                                                outlined
                                                hide-details
                                                v-model="employee.ot_remark"
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="12" class="text-right">
                <v-btn height="30px" dark color="info" class="mr-1" :elevation="1" @click="saveOvertime">Save</v-btn>
                <v-btn height="30px" dark color="deep-orange" :elevation="1">Cancel</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import utility from '../../mixins/utility.mixin';
export default {
    mixins: [utility],
    data(){
        return{
            date: this.toISOLocal().substr(0, 10),
            remark: '',
            department: null,
            designation: null,
            shift: null,
            employee: null,
            results: [],
            loading: false
        }
    },
    methods: {
        getDepartments(){
            this.department = null;
            this.$store.dispatch('department/getDepartments');
        },
        getDesignations(){
            this.designation = null;
            this.$store.dispatch('designation/getDesignations');
        },
        getShifts(){
            this.shift = null;
            this.$store.dispatch('shift/getShifts');
        },
        getEmployees() {
            this.employee = null;
            this.$store.dispatch('employee/getEmployees', {
                apiParams: {
                    departmentId    : this.department?.id ?? null,
                    designationId   : this.designation?.id ?? null,
                    shiftId         : this.shift?.id ?? null,
                },
            })
        },
        async getResults(){
            this.remark = '';
            this.results = [];
            this.loading = true;
            
            this.results = await this.$store
            .dispatch('employee/getEmployees', {
                apiParams: {
                    departmentId : this.department?.id ?? null,
                    designationId: this.designation?.id ?? null,
                    shiftId      : this.shift?.id ?? null,
                    id           : this.employee?.id ?? null,
                },
                options: {
                    returnData : true
                }
            });

            this.results = this.results.map(employee => { 
                employee.ot_date   = this.date;
                employee.ot_hours  = '';
                employee.ot_remark = this.remark;
                return employee;
            })

            this.loading = false;
        },

        setRemark() {
            this.results = this.results.map(employee => { 
                employee.ot_remark = this.remark;
                return employee;
            })
        },
        setDate() {
            this.results = this.results.map(employee => { 
                employee.ot_date = this.date;
                return employee;
            })
        },

        async saveOvertime() {

            let isSuccess = await this.$store.dispatch('ot/saveOvertime', this.results);

            if(isSuccess){
                this.reset();
            }
        },
        reset(){
            this.remark = '';
            this.department = null;
            this.designation = null;
            this.shift = null;
            this.employee = null;
            this.results = [];
            this.date = this.toISOLocal().substr(0, 10);
        }
    },
}
</script>